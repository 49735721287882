.Login {
    width: 1200px;
    max-width: 90%;
    margin: 0px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.Logo {
    width: 50%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px;
}
.Logo img {
    max-width: 100%;
}

.Form {
    margin: 0 auto;
    width: 20%;
    box-sizing: border-box;
    padding-left: 30px;
}

.Input{
    margin-top: 20px;
}

.Error {
    height: 0;
    color: salmon;
}

.Loading {
    position: relative;
    margin: 0px auto;
    width: 43px;
    height: 0px;
}

@media screen and (max-width: 850px) {
    .Login {
        display: block;
    }
    .Logo {
        width: 100%;
    }
    .Form {
        width: 100%;
        padding-left: 0px;
    }

    .Input{
        margin-top: 20px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .Error {
        text-align: center;
    }
    
}