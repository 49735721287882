.Loading {
    width: 43px;
    margin: 100px auto;
}

.Notification {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #425563;
    margin: 0 20px 0 0 !important;
}

.NotificationWrapper {
    width: max-content;
    margin: 150px auto;
}