.Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.Header div{
    width: 25%;
}

.Order p:first-child {
    font-size: 1.6em;
}
.Order p:nth-child(2){
    font-size: 1em;
}

.Order p:nth-child(2) {
    margin-left: 20px;
}

.Info p:first-child {
    font-size: 1.8em;
}
.Info p:nth-child(2){
    font-size: 0.9em;
}

.Body {
    margin: 0px auto;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid white;
}

.Side {
    width: 240px;
    background-color: #EEE;
    box-sizing: border-box;
    padding: 30px 20px;
}

.Side h3 {
    margin-bottom: 15px;
    font-size: 1.5em;
}

.Main {
    width: calc(100% - 240px);
    background-color: #CCC;
    box-sizing: border-box;
    padding: 30px 20px;
}

.Timestamp {
    text-align: center;
    font-size: 1.5em; 
    margin-bottom: 20px;
    font-weight: bold;
}

.Images {
    display: flex;
    flex-wrap: wrap;
}

.Image {
    width: 25%;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.Image img {
    padding: 3px;
    border: 3px solid rgba(255, 255, 255, 0);
    width: 100px;
    height: 100px;
}

.Image img.Border {
    border: 3px solid rgba(255, 0, 0, 1);
}
.Red {
    color: red;
}

.ImageName {
    text-align: center;
    margin: 5px auto;
    font-size: 1.3em;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    display: block;
    width: fit-content;
}

@media screen and (max-width: 860px) {
    .Image {
        width: 50%;
    }
}

@media screen and (max-width: 580px) {
    .Body {
        display: block;
    }

    .Side {
        width: 100%;
    }
    
    .Main {
        width: 100%;
    }
}

@media screen and (max-width: 1100px) { 
    .Header {
        display: block;
        text-align: center;
    } 
    .Header div{
        width: auto;
    }
    .Order {
        margin: 0 auto;
    }
    .Info {
        margin: 0 auto;
    }
}